<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <div>
          <b-card-title>{{ `ผู้ช่วย ${total} คน` }}</b-card-title>
        </div>
        <b-button
          v-if="dataList.length > 0"
          variant="primary"
          :to="{name: 'assistant_create'}"
        >
          <feather-icon icon="PlusIcon" />
          สร้างผู้ช่วย
        </b-button>
      </b-card-header>
      <hr class="m-0">

      <template v-if="dataList.length > 0">
        <!-- Table -->
        <b-table
          :items="dataList"
          :fields="fields"
          :busy="isFetching"
          responsive
          show-empty
          hover
          selectable
          @row-selected="onRowSelected"
        >
          <template #cell(status)="data">
            <b-badge variant="light-success">
              {{ data.value }}
            </b-badge>
          </template>
          <template #cell(id)="data">
            <b-button
              variant="outline-primary"
              size="sm"
              :to="`/assistant/detail/?id=${data.value}`"
            >
              แก้ไข
            </b-button>
          </template>
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>
          <template #empty="">
            <p class="text-center text-muted">
              ไม่มีรายชื่อตัวแทน
            </p>
          </template>
        </b-table>
        <!-- pagination -->
        <b-card-footer>
          <b-row class="">
            <b-col md="4">
              <page-limit-select
                :value="limit"
                @update="onLimitChange"
              />
            </b-col>
            <b-col>
              <pagination-input
                :per-page="limit"
                :total="total"
                @update="(val) => (currentPage = val)"
              />
            </b-col>
          </b-row>
        </b-card-footer>
      </template>

      <template v-else>
        <div class="p-4 text-center">
          <p class="text-muted mb-2">
            ไม่มีผู้ช่วย
          </p>
          <b-button
            variant="primary"
            :to="{name: 'assistant_create'}"
          >
            <feather-icon icon="PlusIcon" />
            เพิ่มผู้ช่วย
          </b-button>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {},
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,
      search: '',
      onSearchTimeout: null,
      fields: [
        {
          key: 'name',
          label: 'ชื่อ',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'email',
          label: 'อีเมล',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'phone',
          label: 'เบอร์โทรศัพท์',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'status',
          label: 'สถานะ',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'id',
          label: '',
          thStyle: {
            minWidth: '200px',
          },
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.agent.isGettingAgents,
    }),
    ...mapGetters(['assistants']),
    dataList() {
      return (
        this.assistants || [
          {
            id: 'id',
            name: 'name',
            email: 'email',
            phone: 'phone',
            status: 'status',
          },
        ]
      )
    },
    pagination() {
      return {
        itemsPerPage: 0,
        totalItems: 0,
      }
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
  },
  watch: {
    currentPage(val) {
      if (val) {
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['getAssistants']),
    fetchData() {
      // this.getAssistants()
    },
    onLimitChange(limit) {
      if (limit) {
        this.selectedLimit = limit
        this.fetchData()
      }
    },
    onDelete() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            //
          }
        })
    },
    onRowSelected(val) {
      this.$router.push(`/assistant/detail/${val[0].id}`)
    },
  },
}
</script>
